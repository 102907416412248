<template>
  <section class="container text-block" :class="theme_color">
    <div v-html="value"></div>
  </section>
</template>

<script>
export default {
  name: 'BlockText',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  }
}
</script>

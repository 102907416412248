<template>
  <section class="container table-block-cols" :class="theme_color">
    <template v-if="getTable(value)">

      <div class="table-wrapper-container" v-if="$device.isDesktopOrTablet || value.table_view ===
      'scroll_without_transform' && !$device.isDesktopOrTablet">
        <div class="table-wrapper"  :style="!$device.isDesktopOrTablet && value.table_view ===
        'scroll_without_transform' && value.table_width_mobile ? {'width': value.table_width_mobile} : {'width': '1200px'}"
        >
          <div class="table-row" v-for="(row, index_row) in getTable(value)"
               :class="{'header-table': value.first_row_header && index_row === 0}">

            <template v-for="(cell, index_cell) in row">

              <div v-if="value.table_cols[index_cell].col_type === 'link_product' && value.first_row_header && index_row
            === 0" :class="value.table_cols[index_cell].col_alignment">
                {{ cell }}
              </div>
              <div v-else-if="value.table_cols[index_cell].col_type === 'link_product' && !value.first_row_header &&
            index_row === 0" :class="value.table_cols[index_cell].col_alignment">
                <template v-for="(prod, idx) in getProductLinks(cell)">
                  <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                  </nuxt-link>
                  <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
                </template>

              </div>
              <div v-else-if="value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0"
                   :class="value.table_cols[index_cell].col_alignment">
                <template v-for="(prod, idx) in getProductLinks(cell)">
                  <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                  </nuxt-link>
                  <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
                </template>
              </div>

              <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (value.first_row_header && index_row
            === 0)">
                <div v-if="value.table_cols[index_cell].col_type === 'link_name'">
                  {{ cell }}
                </div>
              </template>

              <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (!value.first_row_header && index_row === 0)">
                <div v-if="value.table_cols[index_cell].col_type === 'link_name'">
                  <a :href="row[index_cell+1]">{{ cell }}</a>
                </div>
              </template>

              <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (index_row !== 0)">
                <div v-if="value.table_cols[index_cell].col_type === 'link_name'">
                  <a target="_blank" :href="row[index_cell+1]">{{ cell }}</a>
                </div>
              </template>

              <div v-else :class="value.table_cols[index_cell].col_alignment">{{ cell }}</div>
            </template>

          </div>
        </div>

      </div>


      <table class="table-mobile table-mobile-ellipsis"
             v-if="value.table_view === 'scroll_turn' && !$device.isDesktopOrTablet">
        <thead>
        <tr>
          <template v-for="(row, index_row) in getTable(value)" v-if="value.first_row_header && index_row === 0">
            <template v-for="(cell, index_cell) in row">
              <td>{{ cell }}</td>
            </template>
          </template>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(row, index_row) in getTable(value)" v-if="index_row !== 0">
          <template v-for="(cell, index_cell) in row">
            <td v-if="value.table_cols[index_cell].col_type === 'link_product' && value.first_row_header && index_row
            === 0" :class="value.table_cols[index_cell].col_alignment">
              {{ cell }}
            </td>
            <td v-else-if="value.table_cols[index_cell].col_type === 'link_product' && !value.first_row_header &&
            index_row === 0" :class="value.table_cols[index_cell].col_alignment">
              <template v-for="(prod, idx) in getProductLinks(cell)">
                <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                </nuxt-link>
                <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
              </template>

            </td>
            <td v-else-if="value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0"
                :class="value.table_cols[index_cell].col_alignment">
              <template v-for="(prod, idx) in getProductLinks(cell)">
                <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                </nuxt-link>
                <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
              </template>
            </td>

            <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (value.first_row_header && index_row
            === 0)">
              <td v-if="value.table_cols[index_cell].col_type === 'link_name'">
                {{ cell }}
              </td>
            </template>

            <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (!value.first_row_header && index_row === 0)">
              <td v-if="value.table_cols[index_cell].col_type === 'link_name'">
                <a :href="row[index_cell+1]">{{ cell }}</a>
              </td>
            </template>

            <template v-else-if="(value.table_cols[index_cell].col_type === 'link_name' ||
            value.table_cols[index_cell].col_type === 'link') && (index_row !== 0)">
              <td v-if="value.table_cols[index_cell].col_type === 'link_name'">
                <a target="_blank" :href="row[index_cell+1]">{{ cell }}</a>
              </td>
            </template>

            <td v-else :class="value.table_cols[index_cell].col_alignment">{{ cell }}</td>
          </template>
        </tr>
        </tbody>
      </table>


      <div class="part-table-mobile" v-if="value.table_view === 'cards' && !$device.isDesktopOrTablet">
        <div v-for="(row, index_row_main) in getTable(value).length" class="part-table-row">

          <template v-for="(row, index_row) in getTable(value)" v-if="value.first_row_header && index_row === 0">
            <template v-for="(cell, index_cell) in row">
              <div>
                <div>{{ cell }}</div>


                <template v-for="(row, index_row) in getTable(value)"
                          v-if="index_row !== 0 && index_row === index_row_main">
                  <template v-for="(cell, index_cell2) in row" v-if="index_cell2 === index_cell">
                    <div v-if="value.table_cols[index_cell2].col_type === 'link_product' && value.first_row_header && index_row
            === 0" :class="value.table_cols[index_cell2].col_alignment">
                      {{ cell }}
                    </div>
                    <div v-else-if="value.table_cols[index_cell2].col_type === 'link_product' && !value.first_row_header &&
            index_row === 0" :class="value.table_cols[index_cell2].col_alignment">
                      <template v-for="(prod, idx) in getProductLinks(cell)">
                        <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                        </nuxt-link>
                        <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
                      </template>

                    </div>
                    <div v-else-if="value.table_cols[index_cell2].col_type === 'link_product' && index_row !== 0"
                         :class="value.table_cols[index_cell2].col_alignment">
                      <template v-for="(prod, idx) in getProductLinks(cell)">
                        <nuxt-link :to="localePath('/product/' + prod)">{{ prod }}
                        </nuxt-link>
                        <span v-if="idx !== getProductLinks(cell).length-1"> / </span>
                      </template>
                    </div>

                    <template v-else-if="(value.table_cols[index_cell2].col_type === 'link_name' ||
            value.table_cols[index_cell2].col_type === 'link') && (value.first_row_header && index_row
            === 0)">
                      <div v-if="value.table_cols[index_cell2].col_type === 'link_name'">
                        {{ cell }}
                      </div>
                    </template>

                    <template v-else-if="(value.table_cols[index_cell2].col_type === 'link_name' ||
            value.table_cols[index_cell2].col_type === 'link') && (!value.first_row_header && index_row === 0)">
                      <div v-if="value.table_cols[index_cell].col_type === 'link_name'">
                        <a :href="row[index_cell2+1]">{{ cell }}</a>
                      </div>
                    </template>

                    <template v-else-if="(value.table_cols[index_cell2].col_type === 'link_name' ||
            value.table_cols[index_cell2].col_type === 'link') && (index_row !== 0)">
                      <div v-if="value.table_cols[index_cell2].col_type === 'link_name'">
                        <a target="_blank" :href="row[index_cell2+1]">{{ cell }}</a>
                      </div>
                    </template>

                    <div v-else :class="value.table_cols[index_cell2].col_alignment">{{ cell }}</div>
                  </template>
                </template>

              </div>
            </template>
          </template>

        </div>
      </div>

    </template>
  </section>
</template>

<script>

export default {
  name: 'BlockTableCols',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  },
  methods: {
    getTable(obj) {
      return obj.table_data[this.$i18n.locale]
    },
    getProductLinks(data) {
      return data.split('/')
    }
  }
}
</script>

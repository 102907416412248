<template>
  <section class="container imagetext1-2-block" :class="theme_color">
    <div class="content">
      <div>
        <img preload v-if="value.image"
             :src="value.image.url | baseUrl"
             :width="value.image.width"
             :height="value.image.height"
             :alt="alt"
             loading="lazy"
        />
      </div>
      <div>
        <div v-html="header" class="inner-header"></div>
        <div v-html="text"></div>
        <nuxt-link :to="localePath(value.page)" class="link" v-if="value.page">{{ link_name }}</nuxt-link>
        <a v-if="value.url" class="link" :href="value.url" :aria-label="link_name">{{ link_name }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockImage2',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    },
    header() {
      return this.value.header[this.$i18n.locale];
    },
    text() {
      return this.value.text[this.$i18n.locale];
    },
    link_name() {
      if (this.value.link_name[this.$i18n.locale]) {
        return this.value.link_name[this.$i18n.locale];
      } else {
        return this.$t('learn_more');
      }
    },
    alt() {
      return this.value.image.image_translations?.find(x => x.language.slug === this.$i18n.locale)?.title || this.value.image.alt || this.value.image.title;
    },
  },
}
</script>

<template>
  <section class="preview-block" :class="theme_color">
    <div class="container content" v-html="value">
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockPreview',
  props: ['value', 'theme'],
  computed: {
    theme_color () {
      return this.theme.value.theme
    }
  }
}
</script>

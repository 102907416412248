<template>
  <section class="container text-big-block" :class="theme_color">
    <div v-html="value"></div>
  </section>
</template>

<script>
export default {
  name: 'BlockTextBig',
  props: ['value', 'theme'],
  computed: {
    theme_color () {
      return this.theme.value.theme
    }
  }
}
</script>

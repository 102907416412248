<template>
  <section class="container block-categories" :class="theme_color">
      <div v-if="value" class="categories-overview">
        <div v-for="group in subcategoriesGroups">
          <nuxt-link v-for="subcategory in group" :to="localePath(getCategoryUrl(subcategory))"
                    :key="`subcategory-${subcategory.id}`">
            {{ getCategoryLocaleDataSeoFilter(subcategory, activeFilter) }}
            <!-- {{ getCategoryLocaleData(subcategory).name }} -->
          </nuxt-link>
        </div>
      </div>
  </section>
</template>

<script>
const _subcategoriesColumns = 3;

export default {
  name: 'BlockCategories',
  props: ['value', 'theme', 'category', 'seoHeaders', 'activeFilter'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    },
    subcategoriesGroups() {
      const groupLength = Math.ceil(this.category.subcategories.length / _subcategoriesColumns);
      let result = [];
      for (let i = 0; i < _subcategoriesColumns; i++) {
        result.push(this.category.subcategories.slice(i * groupLength, (i + 1) * groupLength));
      }
      return result;
    },
  },
  methods: {
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    getCategoryLocaleDataSeoFilter(category, filter_slug) {
      const seoHeader = this.seoHeaders?.find(x => x.category.id === category.id && x.filter.slug === filter_slug) ||
          {};
      if (Object.keys(seoHeader).length) {
        return seoHeader.header_translations?.find(x => x.language.slug === this.$i18n.locale).header || {};
      } else {
        return category.category_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
      }
    },
    getCategoryUrl(category) {
      let c = this.category;
      let tree = [category, c];
      while (c.parent) {
        tree.push(c.parent);
        c = c.parent;
      }
      tree = tree.reverse().map(x => x.slug)
      if(this.activeFilter){
        tree.push(this.activeFilter)
      }      
      return '/' + tree.join('/') + '/';
      // return '/' + tree.reverse().map(x => x.slug).join('/') + '/';
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container table-block-cols",class:_vm.theme_color},[(_vm.getTable(_vm.value))?[(_vm.$device.isDesktopOrTablet || _vm.value.table_view ===
    'scroll_without_transform' && !_vm.$device.isDesktopOrTablet)?_c('div',{staticClass:"table-wrapper-container"},[_c('div',{staticClass:"table-wrapper",style:(!_vm.$device.isDesktopOrTablet && _vm.value.table_view ===
      'scroll_without_transform' && _vm.value.table_width_mobile ? {'width': _vm.value.table_width_mobile} : {'width': '1200px'})},_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return _c('div',{staticClass:"table-row",class:{'header-table': _vm.value.first_row_header && index_row === 0}},[_vm._l((row),function(cell,index_cell){return [(_vm.value.table_cols[index_cell].col_type === 'link_product' && _vm.value.first_row_header && index_row
          === 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v("\n              "+_vm._s(cell)+"\n            ")]):(_vm.value.table_cols[index_cell].col_type === 'link_product' && !_vm.value.first_row_header &&
          index_row === 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n                ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):(_vm.value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0)?_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n                ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (_vm.value.first_row_header && index_row
          === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_vm._v("\n                "+_vm._s(cell)+"\n              ")]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (!_vm.value.first_row_header && index_row === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (index_row !== 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"target":"_blank","href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:_c('div',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v(_vm._s(cell))])]})],2)}),0)]):_vm._e(),_vm._v(" "),(_vm.value.table_view === 'scroll_turn' && !_vm.$device.isDesktopOrTablet)?_c('table',{staticClass:"table-mobile table-mobile-ellipsis"},[_c('thead',[_c('tr',[_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return (_vm.value.first_row_header && index_row === 0)?[_vm._l((row),function(cell,index_cell){return [_c('td',[_vm._v(_vm._s(cell))])]})]:_vm._e()})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return (index_row !== 0)?_c('tr',[_vm._l((row),function(cell,index_cell){return [(_vm.value.table_cols[index_cell].col_type === 'link_product' && _vm.value.first_row_header && index_row
          === 0)?_c('td',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v("\n            "+_vm._s(cell)+"\n          ")]):(_vm.value.table_cols[index_cell].col_type === 'link_product' && !_vm.value.first_row_header &&
          index_row === 0)?_c('td',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n              ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):(_vm.value.table_cols[index_cell].col_type === 'link_product' && index_row !== 0)?_c('td',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n              ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (_vm.value.first_row_header && index_row
          === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('td',[_vm._v("\n              "+_vm._s(cell)+"\n            ")]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (!_vm.value.first_row_header && index_row === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('td',[_c('a',{attrs:{"href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:((_vm.value.table_cols[index_cell].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell].col_type === 'link') && (index_row !== 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('td',[_c('a',{attrs:{"target":"_blank","href":row[index_cell+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:_c('td',{class:_vm.value.table_cols[index_cell].col_alignment},[_vm._v(_vm._s(cell))])]})],2):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(_vm.value.table_view === 'cards' && !_vm.$device.isDesktopOrTablet)?_c('div',{staticClass:"part-table-mobile"},_vm._l((_vm.getTable(_vm.value).length),function(row,index_row_main){return _c('div',{staticClass:"part-table-row"},[_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return (_vm.value.first_row_header && index_row === 0)?[_vm._l((row),function(cell,index_cell){return [_c('div',[_c('div',[_vm._v(_vm._s(cell))]),_vm._v(" "),_vm._l((_vm.getTable(_vm.value)),function(row,index_row){return (index_row !== 0 && index_row === index_row_main)?[_vm._l((row),function(cell,index_cell2){return (index_cell2 === index_cell)?[(_vm.value.table_cols[index_cell2].col_type === 'link_product' && _vm.value.first_row_header && index_row
          === 0)?_c('div',{class:_vm.value.table_cols[index_cell2].col_alignment},[_vm._v("\n                    "+_vm._s(cell)+"\n                  ")]):(_vm.value.table_cols[index_cell2].col_type === 'link_product' && !_vm.value.first_row_header &&
          index_row === 0)?_c('div',{class:_vm.value.table_cols[index_cell2].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n                      ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):(_vm.value.table_cols[index_cell2].col_type === 'link_product' && index_row !== 0)?_c('div',{class:_vm.value.table_cols[index_cell2].col_alignment},[_vm._l((_vm.getProductLinks(cell)),function(prod,idx){return [_c('nuxt-link',{attrs:{"to":_vm.localePath('/product/' + prod)}},[_vm._v(_vm._s(prod)+"\n                      ")]),_vm._v(" "),(idx !== _vm.getProductLinks(cell).length-1)?_c('span',[_vm._v(" / ")]):_vm._e()]})],2):((_vm.value.table_cols[index_cell2].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell2].col_type === 'link') && (_vm.value.first_row_header && index_row
          === 0))?[(_vm.value.table_cols[index_cell2].col_type === 'link_name')?_c('div',[_vm._v("\n                      "+_vm._s(cell)+"\n                    ")]):_vm._e()]:((_vm.value.table_cols[index_cell2].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell2].col_type === 'link') && (!_vm.value.first_row_header && index_row === 0))?[(_vm.value.table_cols[index_cell].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"href":row[index_cell2+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:((_vm.value.table_cols[index_cell2].col_type === 'link_name' ||
          _vm.value.table_cols[index_cell2].col_type === 'link') && (index_row !== 0))?[(_vm.value.table_cols[index_cell2].col_type === 'link_name')?_c('div',[_c('a',{attrs:{"target":"_blank","href":row[index_cell2+1]}},[_vm._v(_vm._s(cell))])]):_vm._e()]:_c('div',{class:_vm.value.table_cols[index_cell2].col_alignment},[_vm._v(_vm._s(cell))])]:_vm._e()})]:_vm._e()})],2)]})]:_vm._e()})],2)}),0):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="container imagesset3-block border-image" :class="theme_color">
    <div class="content">
      <template v-for="obj in value.filter(x => !!x.filename)">
        <div class="image-block">
          <div class="border">
            <img preload 
              :src="obj.filename | baseUrl"
              loading="lazy"
              :alt="getAlt(obj)"/>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockProductImages',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme;
    },
  },
  methods: {
    getAlt(obj){
      if(this.$i18n.locale === 'ru') return obj.product?.name + ' - обзор'
      else return obj.product?.name
    },
  },
}
</script>

<template>
  <section class="container video-block" :class="theme_color">
    <div v-html="value.video"></div>
  </section>
</template>

<script>
export default {
  name: 'BlockVideo',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  }
}
</script>
